<template>
  <div>
    <!-- <q-select
      dense
      filled
      bg-color="blue-grey-1"
      v-model="pick"
      :options="options"
      label=""
      color="teal"
      options-selected-class="text-deep-orange"
      class="user-pop-search-type"
    >
      <template v-slot:selected>
        
        <q-list dense>
          <q-item tag="label">
            <q-item-section avatar>
              <q-icon :name="pick.icon" class="text-deep-orange"></q-icon>
            </q-item-section>
            <q-item-section>
              <q-item-label class="text-primary"><b>{{pick.label}}</b></q-item-label>
              <q-item-label caption>
                {{pick.description}}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </template>
      <template v-slot:option="scope">
        <q-item
          v-bind="scope.itemProps"
          v-on="scope.itemEvents"
        >
          <q-item-section avatar>
            <q-icon :name="scope.opt.icon"></q-icon>
          </q-item-section>
          <q-item-section>
            <q-item-label v-html="scope.opt.label"></q-item-label>
            <q-item-label caption>{{ scope.opt.description }}</q-item-label>
          </q-item-section>
        </q-item>
      </template>
    </q-select> -->
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <c-select
            type="none"
            :comboItems="options"
            itemText="label"
            itemValue="value"
            name="value"
            label="구분"
            v-model="pick.value"
            @datachange="datachange"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept v-show="pick.value==='userInAll' || pick.value==='selfVendor'" type="search" name="deptCd" v-model="searchParam.deptCd" />
          <c-vendor
            v-show="pick.value==='projectVendor' || pick.value==='userOutAll' || pick.value ==='dayJobAll'"
            :disabled="isVendor"
            :plantCd="popupParam.plantCd"
            companyFlag="N"
            label="업체"
            name="vendorCd"
            v-model="searchParam.vendorCd" />
          <c-select
            v-show="pick.value==='projectOrg'"
            type="search"
            codeGroupCd="SAFETY_ORG_ROLE"
            itemText="codeName"
            itemValue="code"
            name="projectRole"
            label="역할"
            v-model="searchParam.projectRole"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            v-show="pick.value==='projectVendor' || pick.value==='userOutAll'"
            type="search"
            codeGroupCd="SAFETY_VENDOR_ORG_ROLE"
            itemText="codeName"
            itemValue="code"
            name="projectRoleCd"
            label="역할"
            v-model="searchParam.projectRoleCd"
          ></c-select>
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            label="사용자명"
            name="userName"
            v-model="searchParam.userName">
          </c-text>
        </div> -->
      </template>
    </c-search-box>
    <c-table
      ref="userTable"
      title="사용자 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      rowKey="idx"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="선택" icon="check" @btnClicked="select" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'user-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
          deptCd: '',
          isInUserAll: false,
          isOutUserAll: false,
          isAllVendor: false,
          companyFlag: 'N',
          isVendorOrg: false,
          isSelf: false,
          isAttendVendor: false,
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: true,
            style: "width:120px",
          },
          {
            name: 'userName',
            field: 'userName',
            label: '성명',
            align: 'center',
            style: "width:120px",
            sortable: true
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '직책',
            align: 'center',
            style: "width:120px",
            sortable: true
          },
          {
            name: 'spotName',
            field: 'spotName',
            label: '직위',
            align: 'center',
            style: "width:120px",
            sortable: true
          },
        ],
        data: [],
        height: '500px'
      },
      searchParam: {
        plantCd: null,
        deptCd: null,
        vendorCd: '',
        userName: '',
        projectRole: null,
        projectRoleCd: null,
        innerFlag: 'Y'
      },
      pick: {
        value: 'projectOrg'
      },
        // 'userInAll',
      flag: false,
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isVendor() {
      return Boolean(this.popupParam.vendorCd)
    }, 
    disableOrg() {
      return (this.popupParam.isAllVendor) && !this.popupParam.isVendorOrg
    },
    disableDayJob() {
      return (this.popupParam.isAllVendor) && !this.popupParam.isVendorOrg
    },
    disableVendor() {
      return (!this.popupParam.isAllVendor)  && !this.popupParam.isVendorOrg
    },
    options() {
      // isVendorOrg : 모든 조회(안전조직, 참여업체, 시공사) 가능
      // isAllVendor && vendorCd 없는경우 시공사별 참여업체 전체 조회
      // isAllVendor && vendorCd 있는경우 참여업체 하나만
      // isSelf: 시공사인력만 조회
      // isInUserAll: 시공사측 사용자 전체 조회
      // isOutUserAll: 도급업체별 사용자 전체 조회 (vendorCd있는경우 참여업체 하나만)
      let val = [
        {
          label: '공사현장 안전조직 조회',
          value: 'projectOrg',
          description: '공사현장 안전조직에 입력된 담당자를 조회하여 선택할 수 있습니다.(※ 단, 공사현장 정보가 없을시 이용불가)',
          icon: 'admin_panel_settings',
          disable: this.disableOrg || (this.popupParam.isAttendVendor || this.popupParam.isSelf)
        },
        {
          label: '참여업체 인력 조회',
          value: 'projectVendor',
          description: '공사현장 찹여업체 정보에 입력된 인력을 조회하여 선택할 수 있습니다.(※ 단, 공사현장 정보가 없을시 이용불가)',
          icon: 'engineering',
          disable: this.popupParam.isAttendVendor ? false : this.disableVendor || (this.isVendor && (this.popupParam.vendorCd === this.$store.getters.user.selfVendorCd))
        },
        {
          label: '시공사 인력 조회',
          value: 'selfVendor',
          description: '공사현장 찹여업체 정보의 시공사 인력 탭에 입력된 인력을 조회하여 선택할 수 있습니다.(※ 단, 공사현장 정보가 없을시 이용불가)',
          icon: 'engineering',
          disable: this.popupParam.isSelf ? false : this.disableVendor || (this.isVendor && (this.popupParam.vendorCd !== this.$store.getters.user.selfVendorCd))
        },
      ];
      /**
       * unification이 넘어온 경우 isInUserAll은 받지 않게 처리
       */
      if (this.popupParam.isInUserAll) {
        val = [
          {
            label: '시공사측 사용자 전체 조회',
            value: 'userInAll',
            description: '사용자 관리 목록을 조회합니다.',
            icon: 'supervised_user_circle'
          },
        ]
      } else if (this.popupParam.isOutUserAll) {
        val = [
          {
            label: '도급업체별 사용자 전체 조회',
            value: 'userOutAll',
            description: '도급업체별 사용자 목록을 조회합니다.',
            icon: 'supervised_user_circle'
          },
        ]
      } else if (this.popupParam.isDayJobAll) {
        val = [
          {
            label: '도급업체별 일용직 전체 조회',
            value: 'dayJobAll',
            description: '일용직 인원을 조회합니다.',
            icon: 'supervised_user_circle'
          },
        ]
      }

      return val;
    }
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.user.list.url
      // code setting
      // list setting
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd;
      } else {
        this.searchParam.plantCd = sessionStorage.getItem("defaultPlantCd");
      }
      if (this.popupParam.vendorCd) {
        this.searchParam.vendorCd = this.popupParam.vendorCd;
        if (this.popupParam.vendorCd === this.$store.getters.user.selfVendorCd) {
          this.popupParam.companyFlag = 'Y'
        }
      }
      if (this.options && this.options.length > 0) {
        if (this.popupParam.isInUserAll) {
          this.pick = this.$_.find(this.options, { value: 'userInAll', });
        } else if (this.popupParam.isOutUserAll) {
          this.pick = this.$_.find(this.options, { value: 'userOutAll', });
        } else if (this.popupParam.isDayJobAll) {
          this.pick = this.$_.find(this.options, { value: 'dayJobAll', });
        }
        else {
          this.pick = this.options[this.$_.findIndex(this.options, { disable: false })];
        }
      }   
      this.datachange();
    },
    getList() {
      let _searchParam = {
        userName: this.searchParam.userName,
      }
      if (this.pick.value === 'projectOrg') {
        this.$set(_searchParam, 'plantCd', this.searchParam.plantCd)
        this.$set(_searchParam, 'projectRole', this.searchParam.projectRole)
        this.$set(_searchParam, 'innerFlag', 'Y')
      } else if (this.pick.value === 'projectVendor') {
        this.$set(_searchParam, 'plantCd', this.searchParam.plantCd)
        this.$set(_searchParam, 'deptCd', this.searchParam.vendorCd)
        this.$set(_searchParam, 'innerFlag', 'N')
        this.$set(_searchParam, 'projectRoleCd', this.searchParam.projectRoleCd)
      } else if (this.pick.value === 'selfVendor') {
        this.$set(_searchParam, 'plantCd', this.searchParam.plantCd)
        this.$set(_searchParam, 'deptCd', this.searchParam.deptCd)
        this.$set(_searchParam, 'innerFlag', 'Y')
      } else if (this.pick.value === 'userOutAll') {
        this.$set(_searchParam, 'deptCd', this.searchParam.vendorCd)
        this.$set(_searchParam, 'dayJobFlag', 'N')
        this.$set(_searchParam, 'innerFlag', 'N')
        this.$set(_searchParam, 'projectRoleCd', this.searchParam.projectRoleCd)
      } else if (this.pick.value === 'dayJobAll') {
        this.$set(_searchParam, 'plantCd', this.searchParam.plantCd)
        this.$set(_searchParam, 'deptCd', this.searchParam.vendorCd)
        this.$set(_searchParam, 'dayJobFlag', 'Y')
        this.$set(_searchParam, 'innerFlag', 'N')
      } else {
        this.$set(_searchParam, 'deptCd', this.searchParam.deptCd)
        this.$set(_searchParam, 'innerFlag', 'Y')
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = _searchParam;
      this.$http.request((_result) => {
        /**
         * 받는 쪽에서는 동일한 props로 받기 위함
         */
        if (this.pick.value === 'projectOrg') {
          this.$_.forEach(_result.data, item => {
            this.$set(item, 'userId', item.projectRoleUserId)
            this.$set(item, 'userName', item.projectRoleUserName)
          })
        }
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['userTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '사용자를 지정하세요.', // 사용자를 먼저 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData, this.pick);
      }
    },
    datachange() {
      if (this.pick.value === 'projectOrg') {
        this.$refs['userTable'].$refs['compo-table'].clearSelection();
        this.listUrl = selectConfig.pjm.org.list.url;
        this.grid.columns = [
          {
            name: 'projectRoleName',
            field: 'projectRoleName',
            label: '역할',
            align: 'center',
            style: "width:120px",
            sortable: false,
          },
          {
            name: 'projectRoleUserName',
            field: 'projectRoleUserName',
            label: '담당자',
            align: 'center',
            sortable: false,
            style: "width:120px",
          },
          {
            name: 'roleStartDt',
            field: 'roleStartDt',
            label: '근무시작일자',
            align: 'center',
            style: "width:120px",
            sortable: false,
          },
          {
            name: 'roleEndDt',
            field: 'roleEndDt',
            label: '근무종료일자',
            align: 'center',
            style: "width:120px",
            sortable: false,
          },
          {
            name: 'roleAppointDt',
            field: 'roleAppointDt',
            label: '선임일자',
            align: 'center',
            style: "width:120px",
            sortable: false,
          },
        ]
      } else if (this.pick.value === 'projectVendor' || this.pick.value === 'userOutAll') {
        this.$refs['userTable'].$refs['compo-table'].clearSelection();
        if (this.pick.value === 'projectVendor') {
          this.listUrl = selectConfig.pjm.vendorUser.list.url;
        } else {
          this.listUrl = selectConfig.mdm.user.vendor.list.url;
        }
        this.grid.columns = [
          {
            name: 'deptName',
            field: 'deptName',
            label: '업체',
            align: 'center',
            style: "width:150px",
            sortable: true
          },
          {
            name: 'userName',
            field: 'userName',
            label: '성명',
            align: 'center',
            style: "width:120px",
            sortable: true
          },
          {
            name: 'projectRoleName',
            field: 'projectRoleName',
            label: '역할',
            align: 'center',
            style: "width:120px",
            sortable: false,
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '직책',
            align: 'center',
            style: "width:120px",
            sortable: true
          },
          {
            name: 'spotName',
            field: 'spotName',
            label: '직위',
            align: 'center',
            style: "width:120px",
            sortable: true
          },
        ]
      } else if (this.pick.value === 'dayJobAll') {
        this.$refs['userTable'].$refs['compo-table'].clearSelection();
        this.listUrl = selectConfig.mdm.user.vendor.list.url;
        this.grid.columns = [
          {
            name: 'deptName',
            field: 'deptName',
            label: '업체',
            align: 'center',
            sortable: true
          },
          {
            name: 'userName',
            field: 'userName',
            label: '성명',
            align: 'center',
            sortable: true
          },
          {
            name: 'projectRoleName',
            field: 'projectRoleName',
            label: '역할',
            align: 'center',
            style: "width:120px",
            sortable: false,
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '직책',
            align: 'center',
            style: "width:120px",
            sortable: true
          },
          {
            name: 'spotName',
            field: 'spotName',
            label: '직위',
            align: 'center',
            style: "width:120px",
            sortable: true
          },
        ]
      } else if (this.pick.value === 'selfVendor') {
        this.$refs['userTable'].$refs['compo-table'].clearSelection();
        this.listUrl = selectConfig.pjm.vendorUser.self.url;
        this.grid.columns = [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '성명',
            align: 'center',
            sortable: true
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '직책',
            align: 'center',
            sortable: true
          },
          {
            name: 'spotName',
            field: 'spotName',
            label: '직위',
            align: 'center',
            sortable: true
          },
        ]
      } else {
        this.$refs['userTable'].$refs['compo-table'].clearSelection();
        this.listUrl = selectConfig.mdm.user.list.url
        this.grid.columns = [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '성명',
            align: 'center',
            sortable: true
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '직책',
            align: 'center',
            sortable: true
          },
          {
            name: 'spotName',
            field: 'spotName',
            label: '직위',
            align: 'center',
            sortable: true
          },
        ]
      }

      this.getList();
    }
  }
};
</script>
<style lang="sass">
.user-pop-search-type
  .q-field__inner
    padding: 0px !important
    .q-field__control-container
      padding: 0px !important
</style>